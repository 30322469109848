<template>
	<div class="page">
		<div class="option-box">
			<Icon type="ios-redo" @click="back" />
			<Form :model="formItem" inline>
				<FormItem label="任务名称">
					<Input clearable filterable type="text" v-model="formItem.taskName" placeholder="任务名称" />
				</FormItem>
				<FormItem label="任务状态">
					<Select v-model="formItem.nowStatus" clearable filterable>
						<Option v-for="(item, index) in optionList.nowStatus" :value="item.id" :key="index">
							{{ item.name }}</Option>
					</Select>
				</FormItem>
				<FormItem label="任务阶段">
					<Select v-model="formItem.nowStep" clearable filterable>
						<Option v-for="(item, index) in optionList.nowStep" :value="item.id" :key="index">
							{{ item.name }}</Option>
					</Select>
				</FormItem>
				<!--	<FormItem label="风险预警">
					<Select v-model="formItem.fxyj" clearable filterable>
						<Option v-for="(item,index) in optionList.fxyj" :key="index" :value="item.id">{{item.name}}</Option>
					</Select>
        </FormItem>-->
			</Form>
			<Form :model="formItem" inline>
				<FormItem label="提交时间">
					<DatePicker v-model="formItem.createTsStart" type="date" placeholder="开始时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'createTs', 0)" style="width: 120px"></DatePicker>
					<span class="split">至</span>
					<DatePicker v-model="formItem.createTsEnd" type="date" placeholder="结束时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'createTs', 1)" style="width: 120px"></DatePicker>
				</FormItem>
				<FormItem label="初始估价日">
					<DatePicker v-model="formItem.initialEvalDateStart" type="date" placeholder="开始时间"
						format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'initialEvalDate', 0)"
						style="width: 120px"></DatePicker>
					<span class="split">至</span>
					<DatePicker v-model="formItem.initialEvalDateEnd" type="date" placeholder="结束时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'initialEvalDate', 1)" style="width: 120px"></DatePicker>
				</FormItem>
				<FormItem label="重估日期">
					<DatePicker v-model="formItem.lastEvalDateStart" type="date" placeholder="开始时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'lastEvalDate', 0)" style="width: 120px"></DatePicker>
					<span class="split">至</span>
					<DatePicker v-model="formItem.lastEvalDateEnd" type="date" placeholder="结束时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'lastEvalDate', 1)" style="width: 120px"></DatePicker>
				</FormItem>
			</Form>
			<div class="btn-box">
				<div class="btn search searchBtnLog" data-id="1" @click="search">查询</div>
				<div class="btn add" @click="add">添加估价对象</div>
			</div>
		</div>

		<div class="choose-box">
			<TagGroup :tagList="selectList" @close="delSingle"></TagGroup>
		</div>

		<div class="option-line">
			<DownloadBtn @exportData="exportData" :id="1" :type=1 :style="{
          position: 'relative',
          left: 0,
          'background-color': 'rgba(241, 110, 113, 1)',
        }"></DownloadBtn>
			<div class="tips"
				title="注：相同证载地址的估价对象只能提交一次，如果上传的估价对象与已经上传的估价对象存在相同的证载地址，或同一任务中有相同的证载地址，系统不会对相同的估价对象进行重复估价，任务中只保留最早上传的估价对象，如需查询估价结果请在估价查询中进行查询。">
				注：相同证载地址的估价对象只能提交一次，如果上传的估价对象与已经上传的估价对象存在相同的证载地址，或同一任务中有相同的证载地址，系统不会对相同的估价对象进行重复估价，任务中只保留最早上传的估价对象，如需查询估价结果请在估价查询中进行查询。
			</div>
		</div>

		<div class="table-box">
			<div class="tips" v-show="showTips">
				您有待确认的估价结果，确认后才能查看估价结果
			</div>
			<Table :columns="tableColumns" :data="tableData" border tooltip-theme="light" height="530" class="table"
				:loading="loading"></Table>

			<Page :total="total" show-elevator show-sizer @on-page-size-change="pageSizeChange"
				@on-change="pageChange" />
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import DownloadBtn from "@/components/DownloadBtn";
	import formMixin from "@/mixins/form";
	import {
		formatDate
	} from "@/utils";
	import {
		rwlb,
		task_yp_list
	} from "@/api/pledge";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import dataReports from "@/mixins/dataReports";
	export default {
		name: "taskManage",
		mixins: [formMixin, dataReports],
		components: {
			DownloadBtn,
		},
		data() {
			return {
				optionList: {
					nowStatus: [{
							id: 0,
							name: "初始估价",
						},
						{
							id: 1,
							name: "重估",
						},
					],
					nowStep: [{
							id: 1,
							name: "数据上传",
						},
						{
							id: 2,
							name: "系统校验",
						},
						{
							id: 3,
							name: "智能估价",
						},
						{
							id: 4,
							name: "确认估价结果",
						},
						{
							id: 5,
							name: "报告生成",
						},
					],
					fxyj: [{
							id: 0,
							name: "正常",
						},
						{
							id: 1,
							name: "橙色",
						},
						{
							id: 2,
							name: "黄色",
						},
						{
							id: 3,
							name: "红色",
						},
					],
				},
				selectionOption: [{
						name: "任务名称",
						key: "taskName",
						type: "string",
					},
					{
						name: "提交时间",
						key: "createTs",
						type: "range",
					},
					{
						name: "初始估价日",
						key: "initialEvalDate",
						type: "range",
					},
					{
						name: "重估日期",
						key: "lastEvalDate",
						type: "range",
					},
					{
						name: "任务状态",
						key: "nowStatus",
						type: "option",
					},
					{
						name: "任务阶段",
						key: "nowStep",
						type: "option",
					},
					{
						name: "风险预警",
						key: "fxyj",
						type: "option",
					},
					{
						name: "租赁方式",
						key: "leasingMode",
						type: "option",
					},
				],
				formItem: {
					createTs: [],
					current: 1,
					fxyj: "",
					initialEvalDate: [],
					lastEvalDate: [],
					nowStatus: "",
					nowStep: "",
					size: 10,
					taskName: "",
				},

				showTips: false,
				loading: false,
				total: 0,
				tableData: [],
				tableColumns: [
					// {
					// 	title : "重估日期",
					// 	key:"id",
					// 	align : 'center',
					// 	render: (h, params) => {
					// 		return h('div',this.formatDate(params.row.initialEvalDate))
					// 	}
					// },
					{
						title: "任务名称",
						key: "taskName",
						align: "center",
						render: (h, params) => {
							return h(
								"div", {
									attrs: {
										class: "btn-detail",
									},
									on: {
										click: () => this.gotoDetail(params),
									},
								},
								params.row.taskName
							);
						},
					},
					{
						title: "提交时间",
						key: "createTs",
						align: "center",
						render: (h, params) => {
							return h(
								"div",
								params.row.createTs ? this.formatDate(params.row.createTs) : ""
							);
						},
					},
					{
						title: "初始估价日",
						key: "initialEvalDate",
						align: "center",
						render: (h, params) => {
							return h(
								"div",
								params.row.initialEvalDate ?
								this.formatDate(params.row.initialEvalDate) :
								""
							);
						},
					},
					{
						title: "重估日",
						key: "lastEvalDate",
						align: "center",
						render: (h, params) => {
							return h(
								"div",
								params.row.lastEvalDate ?
								this.formatDate(params.row.lastEvalDate) :
								""
							);
						},
					},
					{
						title: "任务状态",
						key: "nowStatus",
						align: "center",
						render: (h, params) => {
							return h("div", params.row.nowStatus == 0 ? "初始估价" : "重估");
						},
					},
					{
						title: "任务阶段",
						key: "nowStep",
						align: "center",
						render: (h, params) => {
							let text = this.optionList.nowStep[parseInt(params.row.nowStep) - 1]
								.name;

							/*if(params.row.nowStep==1){
							text="数据上传";
						}
						if(params.row.nowStep==2){
							text="系统校验";
						}
						if(params.row.nowStep==3){
							text="智能估价";
						}
						if(params.row.nowStep==4){
							text="生成估价报告";
						}
						if(params.row.nowStep==5){
							text="报告生成";
						}*/
							return h("div", text);
						},
					},
					{
						title: "房屋数量",
						key: "ypCount",
						align: "center",
					},
					{
						title: "出值数",
						key: "czCnt",
						align: "center",
					},
					{
						title: "出值率",
						key: "czRate",
						align: "center",
						render: (h, params) => {
							return h(
								"div",
								params.row.czRate == "" ?
								"" :
								parseFloat(params.row.czRate).toFixed(2) + "%"
							);
						},
					},
					{
						title: "风险预警数量",
						key: "yjCount",
						align: "center",
					},
					{
						title: "估价结果",
						align: "center",
						render: (h, params) => {
							let txt = "";
							let data = params.row;
							let step = data.nowStep;
							let des = "";
							if (step <= 4) {
								return h(
									"div", {
										attrs: {
											class: "action-line",
										},
									},
									step < 4 ? "估价中" : "待确认"
								);
							} else {
								return h(
									"div", {
										attrs: {
											class: "action-line",
										},
									},
									[
										/*	h('a',{
										                      attrs : {
										                          class : 'btn-check'
										                      },
										                      on : {
										                          click : ()=> this.gotoDetail(params)
										                      }
										                  },'查看'),*/
										h(
											"a", {
												attrs: {
													class: "btn-download",
												},
												on: {
													click: () => this.itemDownload(data),
												},
											},
											"下载"
										),
									]
								);
							}
						},
					},
				],
				pageSize: 10,
				pageIndex: 1,

				deTailtableColumns: [{
						title: "城市",
						key: "cs",
						align: "center",
						width: "150px",
					},
					{
						title: "证载地址",
						key: "zzdz",
						align: "center",
						width: "300px",
					},
					{
						title: "小区名称",
						key: "xqmc",
						align: "center",
						width: "150px",
					},
					{
						title: "建筑面积(㎡)",
						key: "jzmj",
						align: "center",
						width: "150px",
					},
					{
						title: "估价基准日",
						key: "evalTime",
						align: "center",
						width: "150px",
					},
					{
						title: "单价（元/㎡）",
						key: "pgdj",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
						},
						render: (h, params) => {
							return h("div", params.row.result.pgdj);
						},
					},
					{
						title: "总价（万元）",
						key: "pgzj",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "总价"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							return h(
								"div",
								params.row.result.pgzj == "" ? "" : params.row.result.pgzj / 10000
							);
						},
					},
					{
						title: "单价-修正（元/㎡）",
						key: "fjxzhzZj",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [
								h("div", "调整后单价"),
								h("div", "（元/㎡）"),
							]);
						},
						render: (h, params) => {
							return h("div", params.row.result.fjxzhzDj);
						},
					},
					{
						title: "总价-修正（万元）",
						key: "fjxzhzZj",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "调整后总价"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							return h(
								"div",
								params.row.result.fjxzhzZj == "" ?
								"" :
								params.row.result.fjxzhzZj / 10000
							);
						},
					},
					{
						title: "预警值（万元）",
						key: "yjz",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "预警值"), h("div", "（万元）")]);
						},
					},
					{
						title: "最高抵质押率（%）",
						key: "zgdzyl",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "最高抵质押率"), h("div", "（%）")]);
						},
						render: (h, params) => {
							return h("div", params.row.result.zgdzyl);
						},
					},
					{
						title: "租金价格（元/㎡/月）",
						key: "zjdjpg",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "租金价格"), h("div", "（元/㎡/月）")]);
						},
						render: (h, params) => {
							return h("div", params.row.result.zjdjpg);
						},
					},
					{
						title: "租金（元/月）",
						key: "zjzjpg",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "租金"), h("div", "（元/月）")]);
						},
						render: (h, params) => {
							return h("div", params.row.result.zjzjpg);
						},
					},
					{
						title: "租金价格-修正（元/㎡/月）",
						key: "zjxzhzJg",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [
								h("div", "调整后租金价格"),
								h("div", "（元/㎡/月）"),
							]);
						},
						render: (h, params) => {
							return h("div", params.row.result.zjxzhzJg);
						},
					},
					{
						title: "租金-修正（元/月）",
						key: "zjxzhzZj",
						align: "center",
						width: "150px",
						renderHeader: (h, index) => {
							return h("div", [h("div", "调整后租金"), h("div", "（元/月）")]);
						},
						render: (h, params) => {
							return h("div", params.row.result.zjxzhzZj);
						},
					},
				],
			};
		},

		methods: {
			info1() {

			},
			back() {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/pledgeManage",
				});
			},
			exportData() {
				let params = Object.assign({}, this.formItem);
				params.current = 1;
				params.size = 99999;

				rwlb(params).then((res) => {
					let json = res.records.map((item) => {
						item.czRate = item.czRate * 100 + "%";
						item.createTs && (item.createTs = this.formatDate(item.createTs));
						item.lastEvalDate &&
							(item.lastEvalDate = this.formatDate(item.lastEvalDate));
						item.initialEvalDate &&
							(item.initialEvalDate = this.formatDate(item.initialEvalDate));
						item.nowStatus = item.nowStatus == 0 ? "初始估价" : "重估";
						item.nowStep = this.optionList.nowStep[
							parseInt(item.nowStep) - 1
						].name;
						return item;
					});

					let filterVal = [];
					let tHeader = [];
					this.tableColumns.forEach((item) => {
						tHeader.push(item.title);
						filterVal.push(item.key);
					});
					const data = formatJson(filterVal, json);
					downloadData(tHeader, data, "任务管理");
				});
			},
			search() {
				this.formItem.current = 1;
				this.getList();
			},
			add() {
				this.$router.push({
					name: "新增任务",
					params: {
						data: this.tableData,
						_token: "taskManage",
					},
				});
			},

			getList() {
				rwlb(this.formItem).then((res) => {
					this.showTips = false;
					this.total = res.total;
					this.tableData = res.records.map((item) => {
						item.czRate = item.czRate * 100 + "%";
						if (item.nowStep == 4) {
							this.showTips = true;
						}
						return item;
					});
				});
			},
			gotoDetail(params) {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/taskDetail",
					query: {
						id: params.row.id,
						roundId: params.row.roundId,
					},
				});
			},
			itemCheck(data) {
			},
			itemDownload(data) {
				this.$btnSysLog(1,1)
				let query_params = {
					current: 1,
					size: 99999,
					taskId: data.id,
					roundId: data.roundId,
				};

				task_yp_list(query_params).then((res) => {
					let tableData = res.records.map((item) => {
						for (let key in item.result) {
							item[key] = item.result[key];
						}

						item.evalTime && (item.evalTime = formatDate(item.evalTime));
						item.yjz && (item.yjz = +(item.yjz / 10000).toFixed(2));
						item.pgzj && (item.pgzj = +(item.pgzj / 10000).toFixed(2));
						item.zgkdbed && (item.zgkdbed = +(item.zgkdbed / 10000).toFixed(2));
						item.fjxzhzZj &&
							(item.fjxzhzZj = +(item.fjxzhzZj / 10000).toFixed(2));
						return item;
					});

					let filterVal = [];
					let tHeader = [];
					this.deTailtableColumns.forEach((item) => {
						tHeader.push(item.title);
						filterVal.push(item.key);
					});
					const formatData = formatJson(filterVal, tableData);
					downloadData(tHeader, formatData, data.taskName);
				});
			},

			pageSizeChange(size) {
				this.formItem.current = 1;
				this.formItem.size = size;
				this.getList();
			},
			pageChange(size) {
				this.formItem.current = size;
				this.getList();
			},
			formatDate: function(val) {
				const value = new Date(val);
				const year = value.getFullYear();
				const month = value.getMonth() + 1;
				const day = value.getDate();
				// const hour=(value.getHours());
				// const minutes=(value.getMinutes());
				// const seconds=(value.getSeconds());
				return (
					year +
					"-" +
					(month >= 10 ? month : "0" + month) +
					"-" +
					(day >= 10 ? day : "0" + day)
				);
			},
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			this.info1();
			this.getList();
		},
	};
</script>


<style lang="scss" scoped>
	@import "@/style/common.scss";

	.page {
		@include flex(flex-start, flex-start);
		flex-direction: column;
		height: 100%;
		min-width: 1200px;
		padding: 20px;

		.option-box {
			position: relative;
			width: 100%;
			margin-bottom: 20px;
			padding: 20px 30px;
			border: 1px solid #e3e3e5;

			.ivu-icon-ios-redo {
				position: absolute;
				top: 20px;
				right: 20px;
				color: #00b6ff;
				font-size: 36px;
				transform: rotateY(180deg);
				cursor: pointer;
			}

			/deep/ .ivu-form-item {
				margin-right: 40px;

				.ivu-form-item-content {
					display: inline-block;
				}
			}

			.split {
				margin: 0 15px;
			}

			.btn-box {
				@include flex;
				margin: 20px 0 0;

				.btn {
					@include flex;
					width: 144px;
					height: 40px;
					color: #fff;
					font-size: 18px;
					border-radius: 5px;
					cursor: pointer;

					&.search {
						margin-right: 40px;
						background-color: #00b6ff;
					}

					&.add {
						background-color: #1562d6;
					}
				}
			}
		}

		.choose-box {
			@include flex(flex-start);
			width: 100%;
			margin-bottom: 20px;
		}

		.option-line {
			@include flex(flex-start);
			width: 100%;

			.download-bar {
				width: auto;
				margin-right: 20px;
			}

			.tips {
				@include ellipsis;
			}
		}

		.table-box {
			border: none;
			margin-top: 10px;

			.tips {
				padding-left: 20px;
				color: #f52c2b;
				line-height: 40px;
			}

			/deep/ .btn-detail {
				color: #00b6ff;
				cursor: pointer;
			}

			/deep/ .action-line {
				@include flex;
				color: $themeColor;

				a {
					color: inherit;
				}

				.btn-check {
					margin-right: 20px;
				}
			}

			.ivu-page {
				position: relative;
				margin-top: 15px;
				text-align: right;
			}
		}
	}
</style>
